export default class TemplateSelection {
  constructor(target) {
    this.target = this.getTargetElement(target);
    if (this.target.dataset.templateSelectionInitialized) return;

    this.selectedTemplates = [];
    this.isDropdownOpen = false;
    this.focusedOptionIndex = -1;
    this.placeholder =
      this.target.getAttribute("placeholder") || "Select Applications";

    this.initializeComponent();
    this.target.dataset.templateSelectionInitialized = "true";
  }

  getTargetElement(target) {
    const element =
      typeof target === "string" ? document.querySelector(target) : target;
    if (!(element instanceof HTMLSelectElement)) {
      throw new Error(
        "Target must be a valid selector string or an HTMLSelectElement"
      );
    }
    return element;
  }

  initializeComponent() {
    this.createDOMElements();
    this.setupEventListeners();
    this.updateUI();
    this.target.style.display = "none";
  }

  createDOMElements() {
    this.wrapper = this.createElement("div", "template-selection-wrapper");
    this.displayBox = this.createElement("div", "template-selection-display");
    this.searchBox = this.createElement("input", "template-selection-search", {
      type: "text",
      placeholder: "Search...",
    });
    this.optionsList = this.createElement("ul", "template-selection-options");

    this.target.parentNode.insertBefore(this.wrapper, this.target);
    this.wrapper.append(
      this.target,
      this.displayBox,
      this.searchBox,
      this.optionsList
    );

    this.updateDisplayBox();
    this.updateOptionsList();
    this.optionsList.style.display = "none";
  }

  createElement(tag, className, attributes = {}) {
    const element = document.createElement(tag);
    element.className = className;
    Object.entries(attributes).forEach(([key, value]) =>
      element.setAttribute(key, value)
    );
    return element;
  }

  setupEventListeners() {
    this.displayBox.addEventListener(
      "click",
      this.handleDisplayBoxClick.bind(this)
    );
    this.optionsList.addEventListener(
      "click",
      this.handleOptionSelection.bind(this)
    );
    this.searchBox.addEventListener("input", this.filterOptions.bind(this));
    this.searchBox.addEventListener(
      "keydown",
      this.handleKeyNavigation.bind(this)
    );
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  handleDisplayBoxClick(event) {
    if (!event.target.closest(".template-selection-chip-remove")) {
      this.toggleDropdown();
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.optionsList.style.display = this.isDropdownOpen ? "block" : "none";
    this.searchBox.style.display = this.isDropdownOpen ? "block" : "none";

    if (this.isDropdownOpen) {
      this.searchBox.focus();
      this.displayBox.classList.add("focused");
      this.focusedOptionIndex = -1;
    } else {
      this.searchBox.value = "";
      this.filterOptions();
      this.displayBox.classList.remove("focused");
    }
  }

  handleOutsideClick(event) {
    if (!this.wrapper.contains(event.target) && this.isDropdownOpen) {
      this.closeDropdown();
    }
  }

  handleKeyNavigation(event) {
    if (!this.isDropdownOpen) return;

    const keyActions = {
      ArrowDown: () => this.focusNextOption(),
      ArrowUp: () => this.focusPreviousOption(),
      Enter: () => this.selectFocusedOption(),
      Escape: () => this.closeDropdown(),
    };

    if (keyActions[event.key]) {
      event.preventDefault();
      keyActions[event.key]();
    }
  }

  focusNextOption() {
    const visibleOptions = this.getVisibleOptions();
    if (visibleOptions.length === 0) return;
    this.focusedOptionIndex =
      (this.focusedOptionIndex + 1) % visibleOptions.length;
    this.updateFocusedOption();
  }

  focusPreviousOption() {
    const visibleOptions = this.getVisibleOptions();
    if (visibleOptions.length === 0) return;
    this.focusedOptionIndex =
      (this.focusedOptionIndex - 1 + visibleOptions.length) %
      visibleOptions.length;
    this.updateFocusedOption();
  }

  updateFocusedOption() {
    const visibleOptions = this.getVisibleOptions();
    visibleOptions.forEach((option, index) => {
      option.classList.toggle("focused", index === this.focusedOptionIndex);
    });
  }

  selectFocusedOption() {
    const visibleOptions = this.getVisibleOptions();
    if (
      this.focusedOptionIndex >= 0 &&
      this.focusedOptionIndex < visibleOptions.length
    ) {
      this.handleOptionSelection({
        target: visibleOptions[this.focusedOptionIndex],
      });
    }
  }

  getVisibleOptions() {
    return Array.from(this.optionsList.children).filter(
      (option) => option.style.display !== "none"
    );
  }

  closeDropdown() {
    this.isDropdownOpen = false;
    this.optionsList.style.display = "none";
    this.searchBox.style.display = "none";
    this.searchBox.value = "";
    this.filterOptions();
    this.displayBox.classList.remove("focused");
  }

  handleOptionSelection(event) {
    if (event.target.tagName === "LI") {
      const id = event.target.dataset.value;
      const text = event.target.textContent;

      if (!id || !text.trim()) return;

      this.toggleTemplateSelection(id);
      this.updateUI();
      this.updateOriginalSelect();
    }
  }

  toggleTemplateSelection(id) {
    const index = this.selectedTemplates.indexOf(id);
    if (index > -1) {
      this.selectedTemplates.splice(index, 1);
    } else {
      this.selectedTemplates.push(id);
    }
  }

  updateUI() {
    this.updateOptionsList();
    this.updateDisplayBox();
    this.adjustWrapperHeight();
  }

  updateOptionsList() {
    this.optionsList.innerHTML = "";
    Array.from(this.target.options).forEach((option, index) => {
      const li = this.createElement(
        "li",
        this.selectedTemplates.includes(option.value) ? "selected" : ""
      );
      li.dataset.value = option.value;
      li.dataset.index = index;
      li.innerHTML = this.formatTemplate(option);
      this.optionsList.appendChild(li);
    });
  }

  updateDisplayBox() {
    this.displayBox.innerHTML = "";
    if (this.selectedTemplates.length === 0) {
      this.displayBox.textContent = this.placeholder;
    } else {
      this.selectedTemplates.forEach((id) => {
        const option = this.target.querySelector(`option[value="${id}"]`);
        if (option) {
          const chip = this.createChip(option.text, id);
          this.displayBox.appendChild(chip);
        }
      });
    }
  }

  createChip(text, id) {
    const chip = this.createElement("span", "template-selection-chip");
    chip.innerHTML = `
      ${this.sanitizeHTML(text)}
      <span class="template-selection-chip-remove" data-value="${this.sanitizeHTML(
        id
      )}">&times;</span>
    `;
    chip
      .querySelector(".template-selection-chip-remove")
      .addEventListener("click", (e) => {
        e.stopPropagation();
        this.toggleTemplateSelection(id);
        this.updateUI();
        this.updateOriginalSelect();
      });
    return chip;
  }

  filterOptions() {
    const searchTerm = this.sanitizeInput(this.searchBox.value.toLowerCase());
    Array.from(this.optionsList.children).forEach((li) => {
      const match = li.textContent.toLowerCase().includes(searchTerm);
      li.style.display = match ? "" : "none";
    });
    this.focusedOptionIndex = -1;
    this.updateFocusedOption();
  }

  formatTemplate(template) {
    return this.sanitizeHTML(template.text);
  }

  adjustWrapperHeight() {
    this.wrapper.style.height = this.selectedTemplates.length > 0 ? "auto" : "";
  }

  updateOriginalSelect() {
    Array.from(this.target.options).forEach((option) => {
      option.selected = this.selectedTemplates.includes(option.value);
    });
    this.target.dispatchEvent(new Event("change"));
  }

  getSelectedTemplates() {
    return this.selectedTemplates;
  }

  clearSelection() {
    this.selectedTemplates = [];
    this.updateUI();
    this.updateOriginalSelect();
  }

  sanitizeInput(input) {
    return input.replace(/[&<>"']/g, (char) => {
      const entities = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
      };
      return entities[char] || char;
    });
  }

  sanitizeHTML(html) {
    const tempElement = document.createElement("div");
    tempElement.textContent = html;
    return tempElement.innerHTML;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const selectElements = document.querySelectorAll(
    ".template-selection:not([data-template-selection-initialized])"
  );
  selectElements.forEach((element) => new TemplateSelection(element));
});
