class SectionValidation {
  validate(section, downloadPdfLink) {
    switch (section) {
      case "template_and_upload":
        return this.validateTemplateAndUpload(downloadPdfLink);
      case "account_info":
        return this.validateAccountInfo();
      case "completion":
        return this.validateAgent();
      default:
        console.log("Unknown section");
        return false;
    }
  }

  validateTemplateAndUpload(downloadPdfLink) {
    const templateId = document.getElementById("pdf_submission_template_id");
    const uploadedKey = document.getElementById(
      "pdf_submission_files_added_status"
    );
    if (templateId && templateId.value.trim() === "") {
      alert("Please select the template first.");
      return false;
    } else if (!(uploadedKey && uploadedKey.value.trim() === "true")) {
      alert("Kindly provide the PDF attachment.");
      return false;
    } else {
      if (downloadPdfLink) {
        const url = new URL(downloadPdfLink.href);
        downloadPdfLink.href = `${url.origin}${
          url.pathname
        }?template_id=${templateId.value.trim()}`;
      }
      return true;
    }
  }

  validateAccountInfo() {
    const form = document.querySelector(
      '[data-pdf-extractor--main-target="accountInfoForm"]'
    );
    const inputs = form.querySelectorAll("input[required]");
    let isValid = true;

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        isValid = false;
        input.classList.add("is-invalid");
      } else {
        input.classList.remove("is-invalid");
      }
    });

    if (!isValid) {
      alert("Please fill in all required fields correctly.");
    }

    return isValid;
  }

  validateAgent() {
    const isValid = [...document.getElementsByClassName("agent_detail")].every(
      (agent) =>
        [...agent.querySelectorAll(".pdf-agent-detail")].every(
          (field) => field.value.trim() !== ""
        )
    );
    if (!isValid) {
      alert("Please Enter the Agent(s) information first.");
    }
    return isValid;
  }
}

export default SectionValidation;
