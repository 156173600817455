import { Controller } from "@hotwired/stimulus";
import SectionValidation from "./section_validation";
import UppyHandler from "./UppyHandler";
import TemplateSelection from "./TemplateSelection";

export default class extends Controller {
  static targets = [
    "formStep",
    "filesField",
    "fileTemplate",
    "uppyDashboard",
    "templateSelect",
    "uploadButton",
    "uploadButtonLabel",
    "filesAddedStatus",
    "renewalFlag",
    "accountInfoForm",
  ];
  static values = {
    renewalFlag: Boolean,
    remainingExtractions: Number,
    hasUnlimitedAccess: Boolean,
  };

  connect() {
    console.log("connected with 3 stepper controller");
    this.sectionValidation = new SectionValidation();
    this.checkExtractionLimit();
    this.templateSelection = new TemplateSelection(this.templateSelectTarget);
    this.uppyHandler = new UppyHandler(
      this.uppyDashboardTarget,
      this.filesAddedStatusTarget,
      this.filesFieldTarget
    );
    this.currentStep = 1;
    this.updateStepper();
  }

  checkExtractionLimit() {
    if (!this.hasUnlimitedAccessValue) {
      if (this.remainingExtractionsValue === 0) {
        console.log("You have reached your max limit of premium extractions.");
      } else if (this.remainingExtractionsValue <= 10) {
        console.log(
          `Only ${this.remainingExtractionsValue} extraction(s) left.`
        );
      }
    }
  }

  handleNextBtns(event) {
    const section = event.currentTarget.dataset.section;
    const downloadPdfLink = this.element.querySelector(
      '[data-pdf-extractor--main-target="downloadApplication"]'
    );

    if (this.sectionValidation.validate(section, downloadPdfLink)) {
      if (
        section === "template_and_upload" &&
        this.validateTemplatesAndFiles()
      ) {
        this.moveToStep(1);
      } else if (section === "account_info" && this.validateAccountInfo()) {
        this.submitForm(event);
      }
    } else {
      console.log("Validation failed for section:", section);
    }
  }

  validateTemplatesAndFiles() {
    const selectedTemplates = this.templateSelection.getSelectedTemplates();
    const uploadedFiles = this.uppyHandler.getUploadedFiles();

    if (selectedTemplates.length === 0) {
      alert("Please select at least one template before proceeding.");
      return false;
    }

    if (uploadedFiles.length === 0) {
      alert("Please upload at least one file before proceeding.");
      return false;
    }

    return true;
  }

  validateAccountInfo() {
    const form = this.accountInfoFormTarget;
    const inputs = form.querySelectorAll("input[required]");
    let isValid = true;

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        isValid = false;
        input.classList.add("is-invalid");
        input.nextElementSibling.style.display = "block";
      } else {
        input.classList.remove("is-invalid");
        input.nextElementSibling.style.display = "none";
      }
    });

    if (!isValid) {
      alert("Please fill in all required fields correctly.");
    }
    return isValid;
  }

  handlePrevBtns(event) {
    const section = event.currentTarget.dataset.section;
    if (section === "account_info") {
      this.moveToStep(0);
    } else if (section === "agent_selection") {
      this.moveToStep(1);
    }
  }

  submitForm(event) {
    event.preventDefault();

    if (this.hasUploadButtonLabelTarget) {
      this.uploadButtonLabelTarget.textContent = "Processing...";
    }

    if (this.hasUploadButtonTarget) {
      this.uploadButtonTarget.disabled = true;
    }

    const formData = new FormData();

    // Append template IDs
    const selectedTemplates = this.templateSelection.getSelectedTemplates();
    selectedTemplates.forEach((templateId) => {
      formData.append(`extractionData[template_ids][]`, templateId);
    });

    // Append files
    const uploadedFiles = this.uppyHandler.getUploadedFiles();
    uploadedFiles.forEach((fileObject) => {
      formData.append(`extractionData[files][]`, fileObject.file);
    });

    // Submit the form using fetch
    fetch(this.element.action, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Form submitted successfully:", data);
        this.moveToStep(2);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      })
      .finally(() => {
        if (this.hasUploadButtonLabelTarget) {
          this.uploadButtonLabelTarget.textContent = "Upload";
        }
        if (this.hasUploadButtonTarget) {
          this.uploadButtonTarget.disabled = false;
        }
      });
  }

  moveToStep(stepIndex) {
    this.formStepTargets.forEach((step, index) => {
      if (index === stepIndex) {
        step.classList.add("form-step-active");
        step.style.display = "block";
      } else {
        step.classList.remove("form-step-active");
        step.style.display = "none";
      }
    });
    this.currentStep = stepIndex + 1;
    this.updateStepper();
  }

  updateStepper() {
    const steps = document.querySelectorAll(".stepper .step");
    steps.forEach((step, index) => {
      if (index + 1 < this.currentStep) {
        step.classList.add("completed");
        step.classList.remove("active");
      } else if (index + 1 === this.currentStep) {
        step.classList.add("active");
        step.classList.remove("completed");
      } else {
        step.classList.remove("active", "completed");
      }
    });
  }

  clearForm() {
    this.uppyHandler.clearFiles();
    this.templateSelection.clearSelection();
    this.filesAddedStatusTarget.value = "";
    this.filesFieldTarget.value = "";
    this.moveToStep(0);
  }
}
