import { Controller } from "@hotwired/stimulus";
import "formiojs/dist/formio.full.min.js";
import "jquery-serializejson/jquery.serializejson.js";
import CustomFileUpload from "../../../../../../services/custom_file_upload";
import { parse_to_json, save_data_to_local_storage } from "../../../../../dashboard/javascript/utils";
import { removeRadioButtonsAndCheckboxes, updateFormCheckboxes } from "./utils";
import { filterFormSections, populateFormSections } from "../../../../../applications/sections/form_sections";


export default class extends Controller {
  static values = { formJson: String, isReadOnly: Boolean, sectionsData: String };
  static targets = ["formContainer", "formJson"];

  connect() {
    this.initializeForm();
    this.displayInstructionModalIfNotSeen();
  }

  initialize() {
    this.form = {};
  }

  async initializeForm() {
    this.form = await Formio.createForm(
      this.formContainerTarget,
      parse_to_json(parse_to_json(this.formJsonValue)),
      {
        readOnly: this.isReadOnlyValue,
        fileService: new CustomFileUpload(),
      }
    );

    removeRadioButtonsAndCheckboxes("#formio-form");
    updateFormCheckboxes("formio-form");
    this.createFormSections();
    this.addHoverModals();
  }

  createFormSections() {
    if (!document.getElementById("sections")) return;

    save_data_to_local_storage("sections", filterFormSections(this.form.components));
    populateFormSections();
  }

  addHoverModals() {
    this.traverseComponents(this.form.components);
  }

  traverseComponents(components) {
    components.forEach((component) => {
      if (component.label) {
        this.processComponent(component);
      }

      if (component.components && component.components.length > 0) {
        this.traverseComponents(component.components);
      }
    });
  }

  processComponent(component) {
    const labelSelector = `label[id^="l-"][id$="-${component.key}"]`; // Starts with `l-` and ends with the component key
    let labelElement = document.querySelector(labelSelector);

    if (labelElement) {
      labelElement.classList.add("relative");
      const modal = this.createHoverModal(component);
      const validationObject = component.validate || (component.component && component.component.validate);
      labelElement.appendChild(modal);

      if (validationObject && validationObject.required) {
        modal.querySelector("input[type='checkbox']").checked = true;
      }

      labelElement.addEventListener("mouseenter", () => {
        modal.style.display = "flex";
        modal.style.alignItems = "center";
        modal.style.gap = "8px";
        modal.style.top = "-1.5rem";
        modal.style.left = "1.75rem";
      });

      labelElement.addEventListener("mouseleave", () => modal.style.display = "none");
    }
  }

  createHoverModal(component) {
    const modal = document.createElement("div");
    modal.classList.add("hover-modal");

    const requiredText = document.createElement("span");
    requiredText.innerText = "Required?";
    requiredText.style.fontSize = "12px";

    const switchButton = document.createElement("label");
    switchButton.classList.add("switch-toggle", "mb-0");

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = component.component.validate && component.component.validate.required;

    checkbox.addEventListener('change', (event) => {
      if (!component.component.validate) {
        component.component.validate = {};
      }

      component.component.validate.required = event.target.checked;
      this.updateFormJson();
    });

    const slider = document.createElement("div");
    slider.classList.add("slider-toggle", "round");

    switchButton.appendChild(checkbox);
    switchButton.appendChild(slider);

    modal.appendChild(requiredText);
    modal.appendChild(switchButton);
    modal.style.display = "none";

    return modal;
  }

  updateFormJson() {
    const formSchema = this.form.schema;
    this.formJsonTarget.value = JSON.stringify(formSchema);
  }

  displayInstructionModalIfNotSeen() {
    if (!localStorage.getItem("instructionModalShown")) {
      window.$("#instruction-modal").modal();
    }
  }

  hideInstructionModal() {
    window.$("#instruction-modal").modal("hide");
  }

  setDontShowAgainFlag(event) {
    if (event.target.checked) {
      localStorage.setItem("instructionModalShown", "true");
    }
  }
}
