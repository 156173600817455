import webpackJquery from "jquery";
import { create_element } from "../../../../../themes/manipulate/create";
import { set_session_cookie } from "../../../../../dashboard/javascript/utils";

// export const pagesArrayToShowAfter = () => ["additional-information"];

export const clearSectionsCookies = () => {
  set_session_cookie("sections", "");
  set_session_cookie("form_sections", "");
  set_session_cookie("wizard_sections", "");
};

export const removeRadioButtonsAndCheckboxes = (id = "#formio-wizard") => {
  webpackJquery(`${id} .checkbox`).removeClass("checkbox");
  webpackJquery(`${id} .radio`).removeClass("radio");
};

export const updateFormCheckboxes = (id = "formio-wizard") => {
  let checkboxes = Array.from(
    document.getElementById(id).querySelectorAll(".form-check label")
  );
  checkboxes.push(
    ...document.getElementById(id).querySelectorAll(".form-check-inline label")
  );

  Array.from(checkboxes).forEach((checkbox) => {
    let inputElement = checkbox.querySelector('input[type="checkbox"]');

    if (inputElement) {
      checkbox.classList.add("cntr");

      if (!checkbox.querySelector(".cbx")) {
        let new_el = create_element("div", { class: "cbx" });
        let index = 1;
        let referenceChild = checkbox.children[index];

        if (referenceChild) {
          checkbox.insertBefore(new_el, referenceChild);
        } else {
          checkbox.appendChild(new_el);
        }
      }
    }
  });
};

export const autoSave = (submission) => {
  const url = webpackJquery("#application-submission-form").data("url");
  let formSaved = document.getElementById("form-saved");
  let formSaving = document.getElementById("form-saving");

  if (url !== undefined) {
    formSaved.style.display = "none";
    formSaving.style.display = "block";

    const application_object = webpackJquery(
      "#application-submission-form"
    ).serializeJSON();

    application_object.insurance_application["submission_json"] = JSON.stringify(submission.data);
    $.ajax({
      type: "put",
      url: url,
      data: application_object,
      success: function () {
        formSaved.style.display = "block";
        formSaving.style.display = "none";
        console.log("Saved!");
      },
      error: function () {
        console.log("Not Saved!");
        window.location.reload();
      },
    });
  }
};

export const formSubmitButtonsInit = (submission) => {
  webpackJquery(".form-submit-btn").on("click", async (e) => {
    e.preventDefault();
    const dataValue = e.target.dataset.value;
    if (dataValue === "marked_done") {
      webpackJquery("#application-submission-form")
        .children('input[name="insurance_application[completed_at]"]')
        .val(new Date().toISOString());
      submitForm(submission);
    }
  });
};

export const submitForm = async (submission) => {
  const form = document.getElementById("application-submission-form");
  const formData = new FormData(form);

  const submitButtons = document.querySelectorAll(".form-submit-btn");

  submitButtons.forEach((btn) => {
    btn.disabled = true;
  });

  const response = await fetch(form.action, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
    },
    method: form.method,
    body: formData,
  });

  if (response.ok) {
    const { redirectUrl, html, is_modal } = await response.json();

    if (is_modal) {
      let modal_div = document.getElementById("modal_div");
      modal_div.innerHTML = html;

      window.$(".modal").modal();
      document
        .querySelector(".send_to_outside_email")
        .addEventListener("input", (event) => {
          let el = document.querySelector(".send_to_outside_button");
          const url = new URL(el.href);
          const searchParams = new URLSearchParams(url.search);

          searchParams.set("email", event.target.value);
          url.search = searchParams.toString();
          el.href = url.toString();
        });
    } else {
      window.location.href = redirectUrl;
    }
  } else {
    const { html } = await response.json();
    const errorDiv = document.getElementById("submissions-error");
    errorDiv.innerHTML = html;

    submitButtons.forEach((btn) => {
      btn.disabled = false;
    });
  }
};
