import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {create_element} from "../../../../../../themes/manipulate/create";

export default class extends Controller {
  checkboxEventListener(event) {
    this.updateSelectedItems(event.target);
  }

  updateSelectedItems(checkbox) {
    const selectedItems = document.querySelector("#selectedItems");
    const card2 = document.querySelector("#card2");

    if (checkbox.checked) {
      const newItem = create_element('div', {class: 'list-group-item gap-10 alt-align-center', draggable: 'false'});
      const icon = create_element('i',{class: 'dc-grip-dots handle', style: 'cursor: grab'} );
      const input = create_element('input',{type: 'hidden', name: 'submission_group[template_ids][]', value: checkbox.id} );
      const span = create_element('span', {}, checkbox.value);

      newItem.appendChild(icon);
      newItem.appendChild(span);
      newItem.appendChild(input);

      selectedItems.appendChild(newItem);
    } else {
      Array.from(selectedItems.children).forEach(item => {
        if (item.querySelector('span').textContent === checkbox.value) {
          selectedItems.removeChild(item);
        }
      });
    }

    if (selectedItems.children.length > 0) {
      card2.classList.remove("hidden");
      document.querySelector("#card1").style.flexBasis = "70%";
      card2.style.flexBasis = "30%";
    } else {
      card2.classList.add("hidden");
      document.querySelector("#card1").style.flexBasis = "100%";
      card2.style.flexBasis = "0%";
    }

    const sort = new Sortable(selectedItems, {
      handle: '.handle',
      animation: 150
    });
    this.matchInsuranceApplicationsWithDocuments(checkbox);
  }


  matchInsuranceApplicationsWithDocuments(insuranceApplicationCheckbox) {
    const documentCheckboxesContainer = document.getElementById('company-documents-checkboxes');

    for (let i = 0; i < documentCheckboxesContainer.children.length; i++) {
      const childDiv = documentCheckboxesContainer.children[i];
      const documentCheckbox = childDiv.querySelector('.form-check-input');
      const hiddenInput = childDiv.querySelector('.template-uuid');

      if (hiddenInput && hiddenInput.value === insuranceApplicationCheckbox.id) {
        documentCheckbox.checked = insuranceApplicationCheckbox.checked;
      }
    }

    return null;
  }
}
