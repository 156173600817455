import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";

export default class UppyHandler {
  constructor(dashboardTarget, filesAddedStatusTarget, filesFieldTarget) {
    this.dashboardTarget = dashboardTarget;
    this.filesAddedStatusTarget = filesAddedStatusTarget;
    this.filesFieldTarget = filesFieldTarget;
    this.files = [];
    this.initializeUppy();
  }

  initializeUppy() {
    this.uppyInstance = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ["application/pdf"],
        maxFileSize: 5 * 1024 * 1024,
      },
    }).use(Dashboard, {
      target: this.dashboardTarget,
      inline: true,
      height: 300,
      hideUploadButton: true,
      proudlyDisplayPoweredByUppy: false,
    });

    this.attachEventListeners();
  }

  attachEventListeners() {
    this.uppyInstance.on("file-added", (file) => {
      this.files.push({
        id: file.id,
        content_type: file.type,
        file_name: file.name,
        file: file.data,
      });
      this.updateStatus();
    });

    this.uppyInstance.on("file-removed", (file) => {
      this.files = this.files.filter((item) => item.id !== file.id);
      this.updateStatus();
    });
  }

  updateStatus() {
    if (this.filesAddedStatusTarget) {
      this.filesAddedStatusTarget.value = this.files.length > 0;
    }
    if (this.filesFieldTarget) {
      this.filesFieldTarget.value = JSON.stringify(
        this.files.map(({ id, file, ...meta }) => meta)
      );
    }
  }

  getUploadedFiles() {
    return this.files;
  }

  clearFiles() {
    this.uppyInstance.reset();
    this.files = [];
    this.updateStatus();
  }
}
